var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("layout", [
    _c(
      "div",
      {
        staticClass: "section device",
        attrs: { slot: "content" },
        slot: "content",
      },
      [_c("deviceDetect", { attrs: { hasNext: true } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }