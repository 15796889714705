var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "complete" },
    [
      _c("simple-header", { attrs: { hasRight: false } }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "匿名举报信注意事项",
            visible: _vm.dialogVisible,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "report-content" }, [
            _c("div", [
              _vm._v(
                " 极少数考生通过投机取巧的方式试图获取面试的机会，这对于用心准备、诚信作答的考生来说是极为不公正的行为。 "
              ),
            ]),
            _c("div", [
              _vm._v(
                " 因此国考云开放举报邮箱 exam@iguokao.com，您可以将有作弊嫌疑的相关信息在考试结束后24小时内发送到邮箱里，国考云将根据您提供的信息结合考试监控及数据来进行核对，如发现确实存在作弊行为，国考云将及时反馈给招聘企业，由企业进行进一步处理。 "
              ),
            ]),
            _c("div", [
              _vm._v(
                "因此您的举报信息如包含以下内容，将能帮助我们更快锁定作弊者："
              ),
            ]),
            _c("div", [
              _vm._v(
                " 1.标题：“ 作弊举报+xxxx（企业名称）校招或社招+（考试日期）+作弊者信息（姓名、邮箱或手机号，越详细越能尽快定位到作弊考生）”。 "
              ),
            ]),
            _c("div", [
              _vm._v(
                " 2.内容：描述作弊行为，例如：他人协助作答、传递答案等。 "
              ),
            ]),
            _c("div", [
              _vm._v(
                " 3.图片：微信、qq等通讯工具的截图（ 由于通讯工具个人信息多采用个性昵称，希望您提供的材料能标示出对应的真实信息、姓名手机号、邮箱等对应信息）、作弊现场照片（手机查询信息、多人作答等确凿作弊行为的记录画面或录像 ）。 "
              ),
            ]),
            _c("div", [
              _vm._v(
                " 我们承诺您的信息不会泄露给任何个人及企业机构，请您在发现作弊行为时，放心维护自己的权益。 希望和所有诚信的考生一起维护公正的甄选环境。 "
              ),
            ]),
            _c("div", [_vm._v("举报邮箱：exam@iguokao.com")]),
            _c("div", [_vm._v("国考云")]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("我知道了")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "title" }, [
              _c("i", { staticClass: "iconfont iconkaohe" }),
              _c("span", { staticClass: "label" }, [
                _vm._v(_vm._s(_vm.$store.state.examInfo.scenario) + "结束"),
              ]),
            ]),
            _vm.isShowCandidateScore || _vm.passScore
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "label",
                      staticStyle: {
                        "margin-top": "20px",
                        "font-weight": "bold",
                      },
                    },
                    [
                      _vm._v(" 您的成绩："),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "36px",
                            color: "#cb2a1d",
                          },
                        },
                        [_vm._v(_vm._s(_vm.score.candidateScore.toFixed(2)))]
                      ),
                      _vm._v(" 分 "),
                      _c("div", [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.score.jobPassScore &&
                                  _vm.score.candidateScore >=
                                    _vm.score.jobPassScore,
                                expression:
                                  "\n                  score.jobPassScore &&\n                    score.candidateScore >= score.jobPassScore\n                ",
                              },
                            ],
                          },
                          [
                            _vm._v("考试结果："),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-size": "30px",
                                  color: "#43a6ff",
                                },
                              },
                              [_vm._v("通过")]
                            ),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.score.jobPassScore &&
                                  _vm.score.candidateScore <
                                    _vm.score.jobPassScore,
                                expression:
                                  "\n                  score.jobPassScore &&\n                    score.candidateScore < score.jobPassScore\n                ",
                              },
                            ],
                          },
                          [
                            _vm._v("考试结果："),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-size": "30px",
                                  color: "#cb2a1d",
                                },
                              },
                              [_vm._v("不通过")]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _c("div", {
              staticClass: "detail",
              domProps: { innerHTML: _vm._s(_vm.strReplace(_vm.endNote)) },
            }),
            _c(
              "span",
              {
                staticClass: "cheat",
                on: {
                  click: function ($event) {
                    _vm.dialogVisible = true
                  },
                },
              },
              [_vm._v("发现有人作弊？立即举报")]
            ),
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _vm.checkScore
                  ? _c("CustomButton", {
                      staticClass: "gap",
                      attrs: { title: "查看分数", isPlain: false },
                    })
                  : _vm._e(),
                _vm.isEnableViewReport
                  ? _c("CustomButton", {
                      staticClass: "gap",
                      attrs: { title: "查看报告", isPlain: false },
                    })
                  : _vm._e(),
                _vm.repeatAnswerTimes
                  ? _c("CustomButton", {
                      staticClass: "gap",
                      attrs: { title: "重新作答", isPlain: false },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._m(0),
          ]),
          _c("simple-footer"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "footer" }, [
      _c(
        "a",
        {
          staticClass: "left",
          attrs: { href: "https://www.iguokao.com/", target: "view_window" },
        },
        [
          _c("img", {
            staticStyle: {
              "margin-bottom": "20px",
              width: "150px",
              height: "auto",
              top: "20px",
              bottom: "20px",
            },
            attrs: {
              src: require("../../assets/images/logo_igp.png"),
              alt: "",
            },
          }),
          _c("div", { staticClass: "footer-content" }, [
            _vm._v(" 在线考试，就用国考云 "),
          ]),
        ]
      ),
      _c("span", { staticClass: "line" }),
      _c(
        "a",
        {
          staticClass: "right",
          attrs: { href: "https://www.iguopin.com/", target: "view_window" },
        },
        [
          _c("img", {
            staticStyle: {
              "margin-bottom": "20px",
              width: "150px",
              height: "auto",
              top: "20px",
              bottom: "20px",
            },
            attrs: { src: require("../../assets/images/guopin.png"), alt: "" },
          }),
          _c("div", { staticClass: "footer-content" }, [
            _vm._v(" 找好工作，就上国聘 "),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }