<!--
 * @Author: Libra
 * @Date: 2021-05-21 16:22:31
 * @LastEditTime: 2023-04-13 17:06:19
 * @LastEditors: Libra
 * @Description: 完成考试
 * @FilePath: /stone-exam-ui/src/views/common/Complete.vue
-->
<template>
  <div class="complete">
    <simple-header :hasRight="false" />
    <el-dialog
      title="匿名举报信注意事项"
      :visible.sync="dialogVisible"
      width="800px"
    >
      <div class="report-content">
        <div>
          极少数考生通过投机取巧的方式试图获取面试的机会，这对于用心准备、诚信作答的考生来说是极为不公正的行为。
        </div>
        <div>
          因此国考云开放举报邮箱
          exam@iguokao.com，您可以将有作弊嫌疑的相关信息在考试结束后24小时内发送到邮箱里，国考云将根据您提供的信息结合考试监控及数据来进行核对，如发现确实存在作弊行为，国考云将及时反馈给招聘企业，由企业进行进一步处理。
        </div>
        <div>因此您的举报信息如包含以下内容，将能帮助我们更快锁定作弊者：</div>
        <div>
          1.标题：“
          作弊举报+xxxx（企业名称）校招或社招+（考试日期）+作弊者信息（姓名、邮箱或手机号，越详细越能尽快定位到作弊考生）”。
        </div>
        <div>
          2.内容：描述作弊行为，例如：他人协助作答、传递答案等。
        </div>
        <div>
          3.图片：微信、qq等通讯工具的截图（
          由于通讯工具个人信息多采用个性昵称，希望您提供的材料能标示出对应的真实信息、姓名手机号、邮箱等对应信息）、作弊现场照片（手机查询信息、多人作答等确凿作弊行为的记录画面或录像
          ）。
        </div>
        <div>
          我们承诺您的信息不会泄露给任何个人及企业机构，请您在发现作弊行为时，放心维护自己的权益。
          希望和所有诚信的考生一起维护公正的甄选环境。
        </div>
        <div>举报邮箱：exam@iguokao.com</div>
        <div>国考云</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
type="primary"
@click="dialogVisible = false"
          >我知道了</el-button
        >
      </span>
    </el-dialog>
    <div class="container">
      <div class="content">
        <div class="title">
          <i class="iconfont iconkaohe"></i>
          <span class="label">{{ $store.state.examInfo.scenario }}结束</span>
        </div>
        <div v-if="isShowCandidateScore || passScore">
          <div class="label" style="margin-top: 20px;font-weight:bold">
            您的成绩：<span style="font-size: 36px; color:#cb2a1d">{{
              score.candidateScore.toFixed(2)
            }}</span>
            分
            <div>
              <span
                v-show="
                  score.jobPassScore &&
                    score.candidateScore >= score.jobPassScore
                "
                >考试结果：<span
style="font-size: 30px;color: #43a6ff;"
                  >通过</span
                ></span
              ><span
                v-show="
                  score.jobPassScore &&
                    score.candidateScore < score.jobPassScore
                "
                >考试结果：<span
style="font-size: 30px;color: #cb2a1d;"
                  >不通过</span
                ></span
              >
            </div>
          </div>
        </div>
        <!-- <div class="detail">
          感谢您的参与！您的答案信息已全部保存！<br />
          近期请保持通讯通畅，及时获取后续信息。
        </div> -->
        <div class="detail" v-html="strReplace(endNote)"></div>
        <span
class="cheat"
@click="dialogVisible = true"
          >发现有人作弊？立即举报</span
        >
        <div class="buttons">
          <CustomButton
            class="gap"
            title="查看分数"
            :isPlain="false"
            v-if="checkScore"
          ></CustomButton>
          <CustomButton
            class="gap"
            title="查看报告"
            :isPlain="false"
            v-if="isEnableViewReport"
          ></CustomButton>
          <CustomButton
            class="gap"
            title="重新作答"
            v-if="repeatAnswerTimes"
            :isPlain="false"
          ></CustomButton>
        </div>
        <div class="footer">
          <a class="left" href="https://www.iguokao.com/" target="view_window">
            <img
              style="margin-bottom: 20px; width: 150px; height: auto;top: 20px;bottom: 20px;"
              src="../../assets/images/logo_igp.png"
              alt=""
            />
            <div class="footer-content">
              在线考试，就用国考云
            </div>
          </a>
          <span class="line"></span>
          <a class="right" href="https://www.iguopin.com/" target="view_window">
            <img
              style="margin-bottom: 20px; width: 150px; height: auto;top: 20px;bottom: 20px;"
              src="../../assets/images/guopin.png"
              alt=""
            />
            <div class="footer-content">
              找好工作，就上国聘
            </div>
          </a>
        </div>
      </div>
      <simple-footer />
    </div>
  </div>
</template>

<script>
import SimpleHeader from '@/components/SimpleHeader'
import SimpleFooter from '@/components/SimpleFooter'
import CustomButton from '@/components/CustomButton'
import { MessageBox } from 'element-ui'
import Api from '@/api/api'
import { bus } from '@/utils/bus'
export default {
  components: {
    SimpleHeader,
    SimpleFooter,
    CustomButton
  },
  data() {
    return {
      endNote: '',
      dialogVisible: false,
      repeatAnswerTimes: false,
      checkScore: false,
      passScore: this.$store.state.jobInfo.passScore,
      isShowCandidateScore: this.$store.state.jobInfo.isShowCandidateScore,
      isEnableViewReport: false,
      // 是否是强制结束的
      isForce: false,
      score: {
        candidateScore: 0,
        jobPassScore: 0
      }
    }
  },
  async mounted() {
    setTimeout(() => {
      bus.$emit('destoryMsg')
    }, 2000)
    this.getScore()
    this.isForce = this.$route.query.isForce
    if (this.isForce) {
      MessageBox.confirm(
        '考生您好！由于您考试存在违纪行为，已被监考官结束考试！',
        '考试结束提示',
        {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
    }
    this.endNote = this.$store.state.examInfo.endNote || ''
    this.stopRecorder()
    await this.clearStream()
  },
  methods: {
    // 字符串替换
    strReplace(str) {
      if (!str) return
      const s = str.replace(/&lt;/g, '<')
      return s.replace(/&gt;/g, '>')
    },
    stopRecorder() {
      console.log('stop recorder....')
      if (
        window.cameraRecorder &&
        window.cameraRecorder.state === 'recording'
      ) {
        window.cameraRecorder.stop()
      }
      if (window.recorder && window.recorder.state === 'recording') {
        window.recorder.stop()
      }
      window.recorder = window.cameraRecorder = null
    },
    async clearStream() {
      if (window.client && window.cameraLocalStream) {
        console.log('stop screen stream....')
        const videoTrack = window.cameraLocalStream.getVideoTrack()
        if (videoTrack) {
          await window.cameraLocalStream.removeTrack(videoTrack)
          // 停止采集，关闭摄像头
          videoTrack.stop()
        }
        await window.client.unpublish(window.cameraLocalStream)
        window.cameraLocalStream.close()
        await window.client.leave()
      }
      if (window.screenClient && window.screenLocalStream) {
        console.log('stop screen stream....')
        await window.screenClient.unpublish(window.screenLocalStream)
        window.screenLocalStream.close()
        await window.screenClient.leave()
      }
      console.log('stop stream....')
    },
    async getScore() {
      if (!this.passScore && !this.isShowCandidateScore) return
      const res = await Api.getScore()
      if (res.code === 0) {
        this.score = res.data
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.complete {
  width: 100vw;
  height: 100vh;
  .report-content {
    line-height: 2;
    div {
      margin-bottom: 10px;
    }
  }
}
::v-deep .common-bg {
  min-height: 0;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 75px);
  width: 100%;
  .content {
    width: 664px;
    box-shadow: 0 0 18px #ddd;
    background-color: #fff;
    border-radius: 4px;
    padding: 40px 0 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      .iconfont {
        margin-right: 10px;
        font-size: 70px;
      }
      font-size: 24px;
      color: #cb2a1d;
    }
    .detail {
      margin: 40px 0;
      font-size: 16px;
      line-height: 2;
    }
    .cheat {
      cursor: pointer;
      font-size: 12px;
      color: rgb(89, 89, 89);
    }
    .buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      .gap {
        margin: 0 10px;
        margin-top: 40px;
      }
    }
    .footer {
      margin-top: 40px;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .left {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      .right {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      .line {
        width: 1px;
        height: 100%;
        margin: 0 30px;
        background-color: #ccc;
      }
      .footer-content {
        color: #999;
        font-size: 14px;
      }
    }
  }
}
</style>
