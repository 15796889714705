<!--
 * @Author: Libra
 * @Date: 2023-02-13 18:42:17
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-exam-ui/src/views/confirm/device/index.vue
-->
<template>
  <layout>
    <div slot="content" class="section device">
      <deviceDetect :hasNext="true" />
    </div>
  </layout>
</template>

<script>
import Layout from '@/views/confirm/layout'
import deviceDetect from '@/components/deviceDetect/index.vue'
export default {
  components: {
    Layout,
    deviceDetect
  }
}
</script>

<style lang="scss" scoped>

</style>
