var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "button-container" },
    [
      _c(
        "el-button",
        {
          staticClass: "custom-button",
          class: _vm.classStr,
          attrs: {
            type: "danger",
            plain: _vm.isPlain ? true : false,
            icon: _vm.hasIcon ? _vm.iconName : "",
            disabled: _vm.isDisabled,
          },
          on: { click: _vm.clickMethod },
        },
        [_vm._v(_vm._s(_vm.title))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }