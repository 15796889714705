<!--
 * @Author: Libra
 * @Date: 2021-05-21 11:32:33
 * @LastEditTime: 2023-03-01 10:01:21
 * @LastEditors: Libra
 * @Description: 封装项目中用到的Button组件
 * @FilePath: /stone-exam-ui/src/components/CustomButton/index.vue
-->
<template>
  <div class="button-container">
    <el-button
      class="custom-button"
      type="danger"
      :plain="isPlain ? true : false"
      :icon="hasIcon ? iconName : ''"
      @click="clickMethod"
      :class="classStr"
      :disabled="isDisabled"
      >{{ title }}</el-button
    >
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '我知道了'
    },
    iconName: {
      type: String
    },
    isPlain: {
      type: Boolean,
      default: true
    },
    hasIcon: {
      type: Boolean,
      default: false
    },
    clickMethod: {
      type: Function
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    classStr: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
